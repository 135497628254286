import { copyToClipboard, getWxUrl } from '@/utils';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Cascader,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Space,
  Tag,
  TreeSelect,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { isValidElement, useState } from 'react';
import CompanyEditor from '../../companyInfo/companyEditor';
import ReduceButlerProxy from '../reduceButlerProxy';
import { ReduceDetail } from '../reduceDetail';
import styles from './index.less';
import type DetailModel from './model';

const { SHOW_PARENT } = TreeSelect;
const EmployerDetailModal = observer(({ store }: { store: DetailModel }) => {
  const {
    detailData,
    hotCityList,
    liveTypeTree,
    positionId,
    tagTree,
    setRef,
    onEdit,
    isEdit,
    isOpen,
    onSave,
    onCancel,
    validateLessThan,
    validateMoreThan,
    onOpenSliceModal,
    companyEditorModel,
    openEditCompany,
    reduceButlerProxyStore,
    reduceDetailStore,
  } = store;
  const {
    id,
    status,
    userTypeName,
    name,
    companyName,
    phone,
    sysTagVOList,
    butlerName,
    bdName,
    referrerName,
    channelName,
    subscribeAnchorRuleVO,
    positionCount,
    onLinePositionCount,
    inviteCount,
    inPersonCount,
    giftCount,
    sliceCount,
    slicePeopleCount,
    isVirtual,
    employerId,
    serviceNameList,
    serviceEndTime,
    sourceName,
    invitedCount,
    giftInviteCount,
    payCount,
    selfConnectionCount,
    giftSelfConnectionCount,
    butlerProxyRecruitCount,
    giftButlerProxyRecruitCount,
  } = detailData;
  const { cityList, liveTypeList, minGmv, maxGmv, minPeople, maxPeople } = subscribeAnchorRuleVO || {};
  const isLeaf = (node) => {
    return node.isLeaf;
  };
  return (
    <>
      <Modal
        title={
          <Space>
            雇主信息
            {status === 0 ? <Tag color="green">正常</Tag> : <Tag color="red">封禁</Tag>}
          </Space>
        }
        className={styles.detailModal}
        centered={true}
        open={isOpen}
        onCancel={onCancel}
        maskClosable={false}
        width="60%"
        footer={
          !isEdit
            ? [
                positionId && isVirtual ? (
                  <Button
                    key="bind"
                    type="primary"
                    onClick={() => {
                      getWxUrl({
                        channel: 10,
                        employerId: id,
                      }).then((url) => {
                        copyToClipboard(url);
                        message.success('复制链接成功');
                      });
                    }}
                  >
                    绑定雇主
                  </Button>
                ) : null,
                <Button
                  key="edit"
                  type="primary"
                  onClick={onEdit}
                >
                  编辑
                </Button>,
              ]
            : [
                <Button
                  key="save"
                  type="primary"
                  onClick={onSave}
                >
                  确定
                </Button>,
                <Button
                  key="cancel"
                  type="primary"
                  onClick={onCancel}
                >
                  取消
                </Button>,
              ]
        }
      >
        {/* 此处Form只能当做布局用了 */}
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          style={{ width: '100%' }}
          labelAlign="left"
          disabled={!isEdit}
          ref={setRef}
        >
          <Form.Item label="ID">{id}</Form.Item>
          <Form.Item label="服务名称">
            <div className="flex ">
              {serviceNameList?.map((item) => (
                <div
                  className="ml-1"
                  key={item}
                >
                  {item}
                </div>
              ))}
              {serviceEndTime ? `(${dayjs(serviceEndTime).format('YYYY-MM-DD HH:mm:ss')})` : ''}
            </div>
          </Form.Item>
          <Form.Item
            label="雇主昵称"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="联系手机号"
            name="contactMobile"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="联系微信号"
            name="wxCode"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="联系方式"
            name="contactInfo"
          >
            <Input />
          </Form.Item>
          <Form.Item label="微信绑定手机号">{phone}</Form.Item>
          <Form.Item
            name="sysTagVOList"
            layout="horizontal"
            label="雇主标签"
            wrapperCol={{ span: 12 }}
            // TODO:待回写sysTagVOList做为默认值
          >
            <TreeSelect
              fieldNames={{ label: 'name', value: 'id' }}
              style={{ minWidth: '300px', maxWidth: '400px' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="请选择标签"
              labelInValue={true}
              allowClear={true}
              multiple={true}
              treeCheckable={true}
              treeData={tagTree}
              showCheckedStrategy={'SHOW_CHILD'}
            />
          </Form.Item>

          <Form.Item label="招聘管家">{butlerName}</Form.Item>
          <Form.Item label="BD姓名">{bdName}</Form.Item>
          <Form.Item label="注册推荐人">{referrerName}</Form.Item>

          <Form.Item label="注册来源">{channelName}</Form.Item>
          {/* <Form.Item label="推荐页面">{channelName}</Form.Item> */}
          <Form.Item label="公司名称">
            {companyName}
            {isEdit && (
              <EditOutlined
                className="text-primary cursor-pointer"
                onClick={openEditCompany}
              />
            )}
          </Form.Item>
          <Form.Item label="职位数/上线职位数">{`${positionCount}个/${onLinePositionCount}个`}</Form.Item>
          <Form.Item label="自主建联卡次数/赠送次数">{`${selfConnectionCount}次/${giftSelfConnectionCount}次`}</Form.Item>
          <Form.Item label="管家代招次数/赠送次数">
            {`${butlerProxyRecruitCount}次/${giftButlerProxyRecruitCount}次`}
            <Button
              type="link"
              disabled={false}
              onClick={() => store.reduceButlerProxyStore.handleOpen(butlerProxyRecruitCount || 0, store.employerId)}
            >
              扣减次数
            </Button>
            <Button
              type="link"
              disabled={false}
              onClick={() => store.reduceDetailStore.handleOpen(store.employerId)}
            >
              扣减明细
            </Button>
          </Form.Item>
          <Form.Item label="支付次数">
            {`${payCount}次`}
            <a
              className="ml-2"
              onClick={() => {
                window.top.RingPermission.openTab(`/pbb-pc-management/order/allOrder?id=${id}`, '10', '全部订单');
              }}
            >
              查看明细
            </a>
          </Form.Item>

          {/* <Form.Item
            label="备选信息"
            className="text-primary"
          >
            <a onClick={onOpenSliceModal}> {`${slicePeopleCount || 0}人`}</a>
          </Form.Item> */}
        </Form>
      </Modal>
      <CompanyEditor store={companyEditorModel} />
      <ReduceButlerProxy store={reduceButlerProxyStore} />
      <ReduceDetail store={reduceDetailStore} />
    </>
  );
});

export { EmployerDetailModal };
