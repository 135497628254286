import { type BaseData, request } from '@/utils';
import { type FormInstance, Modal } from 'antd';
import { action, observable } from 'mobx';
import type DetailModel from '../employerDetail/model';

export default class ReduceButlerProxyStore {
  constructor(parentModel: DetailModel) {
    this.parentModel = parentModel;
    // this.handleOpen(1,1)
  }

  @observable public visible = false;
  @observable public parentModel: DetailModel = null;
  @observable public form: FormInstance = null;
  @observable public max = 0;
  @observable public currentUrlsNumber = 0; // 已经存在凭证数量
  public maxUrlsNumber = 3; // 最大凭证数

  @observable public employerId: number = null; // 雇主id

  @action public handleOpen = (max: number, employerId: number) => {
    this.visible = true;
    this.max = max;
    this.employerId = employerId;
    setTimeout(() => {
      this.form?.setFieldValue('count', max > 0 ? 1 : undefined);
    });
  };
  @action public handleClose = () => {
    this.visible = false;
    this.form.resetFields();
  };
  @action public setForm = (ref: FormInstance) => {
    this.form = ref;
  };
  public confirm = async () => {
    await this.form.validateFields();
    Modal.confirm({
      content: '确认扣减该雇主管家代招次数吗',
      onOk: async () => {
        const postParams = { ...this.form.getFieldsValue(), employerId: this.employerId };
        await request<BaseData<any>>({
          method: 'POST',
          url: '/pbb/platform/sys/employer/decrement/count',
          data: {
            ...postParams,
            url: postParams.url?.join(','),
          },
        });
        this.handleClose();
        this.parentModel?.onCancel?.();
        this.parentModel?.parentStore?.onRefresh?.();
      },
    });
  };
}
