import { ASSETS } from '@/assets';
import { type BaseData, MainSubStructureModel, request, requireSource } from '@/utils';
import { Image } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import type { ButlerToFaceRecordVO } from '../type';

export class ReduceDetailModel {
  @observable public parent: any;
  @observable public visible = false;
  @observable public employerId: string | number;

  constructor(parent: any) {
    this.parent = parent;
  }

  @action handleOpen = (employerId: number) => {
    this.visible = true;
    this.employerId = employerId;
    this.mainSubStructureModel.gridModel.onQuery();
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: 'serviceRecord',
    grid: {
      columns: [
        {
          key: 'count',
          name: '扣减管家代招次数',
          formatter: ({ row }) => `${row.count}次`,
        },
        {
          key: 'memo',
          name: '说明',
          formatter: ({ row }) => row.memo || '--',
        },
        {
          key: 'url',
          name: '到面凭证',
          width: 300,
          formatter: ({ row }) => {
            const urls = row.url?.split(',') || [];
            return (
              <div className="w-[100%] h-[100%] flex items-center gap-2">
                {urls.map((url, index) => (
                  <Image
                    key={index.toString()}
                    src={url}
                    height={70}
                  />
                ))}
              </div>
            );
          },
        },
        {
          key: '操作人',
          name: '操作人',
          formatter: ({ row }) => {
            const { updateBy, updateByName, updateByMobile } = row;
            return (
              <div className="flex items-center cursor-pointer h-full gap-2">
                <div className="text-12 leading-[18px]">
                  <div>用户昵称：{updateByName}</div>
                  <div>ID：{updateBy}</div>
                  <div>手机号：{updateByMobile}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'updateTime',
          name: '操作时间',
          formatter: ({ row }) => {
            const { updateTime } = row;
            if (updateTime?.[1]) {
              updateTime[1]--;
            }
            return updateTime
              ? dayjs(new Date(...(updateTime as ButlerToFaceRecordVO['updateTime']))).format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rowHeight: 80,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },

    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const postParams = {
          ...params,
          filterParams: undefined,
        };
        return request<BaseData<any>>({
          url: '/pbb/platform/sys/employer/butler/to/face/record/list',
          method: 'POST',
          data: {
            id: this.employerId,
            ...postParams,
          },
        });
      },
    },
  });

  @action onClose = () => {
    this.visible = false;
  };
}
