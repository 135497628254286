import { MainSubStructure } from '@/utils';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import type { ReduceDetailModel } from './model';

export const ReduceDetail = observer(({ store }: { store: ReduceDetailModel }) => {
  const { visible, onClose, mainSubStructureModel } = store;
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title="扣减明细"
      centered={true}
      closable={true}
      width={1000}
    >
      <div style={{ height: '500px' }}>
        <MainSubStructure store={mainSubStructureModel} />
      </div>
    </Modal>
  );
});
