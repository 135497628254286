import { EpUpload } from '@/utils';
import compressImgOrVideo from '@/utils/compress';
import { DeleteOutlined, FastBackwardFilled, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Image, Input, InputNumber, Modal, Upload, message } from 'antd';
import type { RcFile } from 'antd/es/upload';
import { observer } from 'mobx-react';
import React, { useState, useRef } from 'react';
import type ReduceButlerProxyStore from './model';

const ReduceButlerProxy = observer(({ store }: { store: ReduceButlerProxyStore }) => {
  return (
    <Modal
      title="扣减管家代招次数"
      open={store.visible}
      onCancel={store.handleClose}
      maskClosable={true}
      width={600}
      okButtonProps={{ className: 'mr-16' }}
      onOk={store.confirm}
    >
      <Form
        ref={store.setForm}
        labelCol={{ span: 4 }}
        layout="horizontal"
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="扣减次数"
          name="count"
          rules={[{ required: true }]}
        >
          <InputNumberCustom
            store={store}
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          label="说明"
          name="memo"
          rules={[{ max: 200 }]}
        >
          <Input.TextArea placeholder="请输入" />
        </Form.Item>
        <ImageUploadMultiple
          name="url"
          label="到面凭证"
          message=""
          store={store}
        />
      </Form>
    </Modal>
  );
});
const InputNumberCustom = observer(
  ({
    value,
    onChange,
    store,
    ...rest
  }: { value?: number; onChange?: () => any; store: ReduceButlerProxyStore; [key: string]: any }) => {
    return (
      <>
        <InputNumber
          rootClassName="w-[100px]"
          value={value}
          onChange={onChange}
          className="w-50"
          min={0}
          max={store.max}
          {...rest}
        />
        <span className="ml-1">次</span>
      </>
    );
  },
);
const ImageUploadMultiple = observer(
  (props: {
    readonly name: string;
    readonly label: string;
    readonly message: string;
    store: ReduceButlerProxyStore;
  }) => {
    const [loading, setLoading] = useState(false);
    const fileListRef = useRef<(RcFile | File)[]>([]);
    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={(prevValues: any, nextValues: any) => {
          return prevValues[props.name] !== nextValues[props.name];
        }}
      >
        {({ getFieldValue, setFieldValue }) => {
          const imageUrl = getFieldValue(props.name) || [];

          const customRequest = async () => {
            setLoading(true);
            const fileList = await Promise.all(
              fileListRef.current.map(async (item: File | RcFile) => {
                return (await compressImgOrVideo(item)) as File;
              }),
            );
            const urls = await EpUpload.multiUpload({
              attachmentType: 1,
              module: 'companyAlbum',
              fileList,
            });
            const _urls = getFieldValue(props.name) || [];
            const currentUrls = [..._urls, ...urls];
            setFieldValue(props.name, currentUrls);
            props.store.currentUrlsNumber = currentUrls.length;
            fileListRef.current = [];
            setLoading(false);
          };

          const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
            if (fileList.length > 3) {
              fileList = fileList.splice(0, 3);
            }
            console.log('beforeUpload', file);
            if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp') {
              message.error('图片格式只能为jpg、jpeg、png、webp');
              return false;
            }

            // 限制文件大小，例如限制为 2MB
            const isLt6M = file.size / 1024 / 1024 < 6;
            if (!isLt6M) {
              console.error('图片需要小于6MB!');
              return false;
            }
            if (fileListRef.current.length === 0) {
              fileListRef.current = fileList;
              customRequest();
            }

            return false;
          };

          return (
            <Form.Item
              label={props.label}
              name={props.name}
              getValueFromEvent={() => imageUrl}
              validateTrigger={false}
            >
              <div className="flex items-center gap-2 flex-wrap">
                {imageUrl?.map((url: string, index: number) => {
                  return (
                    <div
                      key={`${index.toString()}`}
                      className="flex items-center justify-center w-[100px] h-[100px] rounded-2 box-content"
                      style={{
                        border: '1px solid #d9d9d9',
                      }}
                    >
                      <Image
                        style={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                        }}
                        src={url}
                        preview={{
                          mask: (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const urls = getFieldValue(props.name) || [];
                                const currentUrls = urls.filter((_url: string) => _url !== url);
                                setFieldValue(props.name, currentUrls);
                                props.store.currentUrlsNumber = currentUrls.length;
                              }}
                            >
                              <DeleteOutlined style={{ fontSize: 24 }} />
                            </div>
                          ),
                        }}
                      />
                    </div>
                  );
                })}
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  multiple={true}
                  accept=".jpg,.jpeg,.png,.webp"
                  disabled={props.store.currentUrlsNumber >= props.store.maxUrlsNumber}
                >
                  <button
                    style={{
                      border: 0,
                      background: 'none',
                    }}
                    type="button"
                  >
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div className="mt-2">上传</div>
                  </button>
                </Upload>
              </div>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  },
);
export default ReduceButlerProxy;
